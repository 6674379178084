export const translations = {
    en: {
      home: "Home",
      aboutUs: "About Us",
      equipment: "Equipment",
      howItWorks: "How It Works",
      rentNow: "Rent Now",
      hero: {
        title: "Rent Professional Photography Equipment in the Netherlands",
        subtitle: "High-quality gear for your creative projects",
        cta: "Start Renting",
      },
      featuredEquipment: {
        title: "Featured Equipment",
        quickReserve: "Quick Reserve",
        searchEquipment: "Search equipment...",
        category: "Category",
        price: "Price",
        noDescription: "No description available.",
        viewDetails: "View Details",
      },
      services: {
        title: "Our Services",
        flexibleBooking: "Flexible Booking",
        flexibleBookingDesc: "Book equipment for a day or a month",
        deliveryOptions: "Delivery Options",
        deliveryOptionsDesc: "Get equipment delivered to your location",
        support: "24/7 Support",
        supportDesc: "Our team is always here to help you",
        bestPrice: "Best Price",
        bestPriceDesc: "We offer competitive prices for top gear",
      },
      contact: {
        title: "Contact Us",
        getInTouch: "Get in Touch",
        questions: "Have questions? We're here to help!",
        email: "Email:",
        phone: "Phone:",
        address: "Address:",
        name: "Name",
        message: "Message",
        send: "Send Message",
      },
      footer: {
        empowering: "Empowering photographers with top-notch equipment",
        rights: "All rights reserved.",
        privacyPolicy: "Privacy Policy",
      },
      aboutUs_ourStory: "Our Story",
      aboutUs_ourStoryContent: "Founded in 2024 by Aleksandra Safronova, FotoRent emerged from a passion for photography and a vision to make high-quality equipment accessible to all creatives. As a one-person business, we've quickly moved from idea to realization, and are now actively serving the photography community in the Netherlands.",
      aboutUs_ourMission: "Our Mission",
      aboutUs_ourMissionContent: "At FotoRent, our mission is simple yet powerful: to make professional photography equipment accessible for everyone's creative endeavors. We believe in expanding the boundaries of creativity by providing easy access to top-notch gear.",
      aboutUs_whatSetsUsApart: "What Sets Us Apart",
      aboutUs_whatSetsUsApartContent: "As a small, agile company, we pride ourselves on our ability to provide personalized service and rapid communication. Our size is our strength, allowing us to be flexible and responsive to our clients' needs.",
      aboutUs_meetTheFounder: "Meet the Founder",
      aboutUs_meetTheFounderContent: "Aleksandra Safronova, the heart and soul of FotoRent, is currently on maternity leave, caring for her daughter. A huge fan of cinema and photography, Aleksandra's goal is to help creators bring their visions to life by making professional equipment accessible to everyone.",
      aboutUs_lookingAhead: "Looking Ahead",
      aboutUs_lookingAheadContent: "Our vision for the future is clear: we aim to build a strong client base, establish a solid reputation, and continuously expand our catalogue of rental equipment. We're committed to growing alongside the creative community we serve.",
      aboutUs_legalInformation: "Legal Information",
      aboutUs_businessForm: "Business Form: Eenmanszaak",
      aboutUs_kvkNumber: "Chamber of Commerce (KvK) Number: 94707367",
      aboutUs_vatNumber: "VAT Number: NL005103021B56",
      aboutUs_contactUs: "For any inquiries or to rent equipment, please don't hesitate to reach out. We're here to help bring your creative projects to life.",
      equipment_description: "Explore our wide range of professional photography and videography equipment available for rent. Whether you're a seasoned pro or an enthusiastic beginner, we have the gear to bring your creative vision to life.",
      category_camera: "Camera",
      category_stabilizer: "Stabilizer",
      category_lighting: "Lighting",
      category_lens: "Lens",
      specifications: "Specifications",
      category_audio: "Audio",
      category_drone: "Drone",
      category_support: "Support",
      howItWorks_description: "Renting photography and videography equipment with FotoRent is easy and straightforward. Follow these simple steps to get the gear you need for your next creative project.",
      howItWorks_step1_title: "Choose Your Gear",
      howItWorks_step1_description: "Browse our extensive catalogue and select the equipment you need for your project.",
      howItWorks_step2_title: "Select Rental Dates",
      howItWorks_step2_description: "Choose the dates you need the equipment. You can rent for as short as one day or as long as you need.",
      howItWorks_step3_title: "Make a Reservation",
      howItWorks_step3_description: "Complete your booking by providing your details and making a secure payment through our partner, GearBooker.",
      howItWorks_step4_title: "Pickup or Delivery",
      howItWorks_step4_description: "Collect your gear from our location or opt for our convenient delivery service.",
      howItWorks_step5_title: "Enjoy Your Rental",
      howItWorks_step5_description: "Use the equipment for your project. Our support team is always available if you need any assistance.",
      howItWorks_step6_title: "Return the Equipment",
      howItWorks_step6_description: "Return the gear to our location or schedule a pickup. Make sure it's in the same condition as when you received it.",
      howItWorks_importantInfo: "Important Information",
      howItWorks_importantInfo1: "A valid ID and credit card are required for all rentals.",
      howItWorks_importantInfo2: "We offer insurance options to protect against accidental damage or loss.",
      howItWorks_importantInfo3: "Late returns may incur additional charges.",
      howItWorks_importantInfo4: "Cancellations must be made at least 48 hours before the rental start time for a full refund.",
      howItWorks_importantInfo5: "For any questions or special requests, please contact our customer support team.",
      privacyPolicy: "Privacy Policy",
      privacyPolicy_introduction: "Introduction",
      privacyPolicy_informationCollected: "Information We Collect",
      privacyPolicy_useOfInformation: "How We Use Your Information",
      privacyPolicy_dataSharing: "Data Sharing and Disclosure",
      privacyPolicy_yourRights: "Your Rights",
      privacyPolicy_dataSecurity: "Data Security",
      privacyPolicy_policyChanges: "Changes to This Policy",
      privacyPolicy_contactUs: "Contact Us",
      privacyPolicy_introductionContent: "At FotoRent, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website and services. We comply with the General Data Protection Regulation (GDPR) and Dutch privacy laws to ensure that your privacy rights are respected and upheld.",
      privacyPolicy_informationCollectedContent1: "We collect the following information that you provide directly to us:",
      privacyPolicy_informationCollectedItem1: "Contact information (name, email address, phone number)",
      privacyPolicy_informationCollectedItem2: "Communications with us (e.g., inquiries, customer support requests)",
      privacyPolicy_note: "Note:",
      privacyPolicy_informationCollectedContent2: "We do not collect account information (such as username and password), payment information (such as credit card details and billing address), or rental history and preferences, as our site does not currently support such functionalities.",
      privacyPolicy_useOfInformationContent: "We use your information to:",
      privacyPolicy_useOfInformationItem1: "Provide, maintain, and improve our services",
      privacyPolicy_useOfInformationItem2: "Respond to your comments, questions, and requests",
      privacyPolicy_useOfInformationItem3: "Send you technical notices, updates, and support messages",
      privacyPolicy_useOfInformationItem4: "Personalize your experience on our website",
      privacyPolicy_dataSharingContent: "We do not sell your personal information. We may share your information in the following circumstances:",
      privacyPolicy_dataSharingItem1: "Service providers who perform services on our behalf",
      privacyPolicy_dataSharingItem2: "Legal and regulatory authorities, as required by law",
      privacyPolicy_dataSharingItem3: "Business partners, with your consent",
      privacyPolicy_yourRightsContent: "Under the GDPR and Dutch privacy laws, you have the right to:",
      privacyPolicy_yourRightsItem1: "Access your personal data",
      privacyPolicy_yourRightsItem2: "Correct inaccurate or incomplete data",
      privacyPolicy_yourRightsItem3: "Request deletion of your data",
      privacyPolicy_yourRightsItem4: "Object to or restrict certain processing of your data",
      privacyPolicy_yourRightsItem5: "Data portability",
      privacyPolicy_dataSecurityContent: "We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage. However, please note that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.",
      privacyPolicy_policyChangesContent: "We may update this policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the 'Last updated' date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.",
      privacyPolicy_contactUsContent: "If you have any questions about this Privacy Policy, please contact us at:",
      privacyPolicy_lastUpdated: "Last updated:",
    },
    nl: {
      home: "Home",
      aboutUs: "Over Ons",
      equipment: "Apparatuur",
      howItWorks: "Hoe Het Werkt",
      rentNow: "Nu Huren",
      hero: {
        title: "Huur Professionele Fotografie Apparatuur in Nederland",
        subtitle: "Hoogwaardige apparatuur voor uw creatieve projecten",
        cta: "Begin met Huren",
      },
      featuredEquipment: {
        title: "Uitgelichte Apparatuur",
        quickReserve: "Snel Reserveren",
        searchEquipment: "Zoek apparatuur...",
        category: "Categorie",
        price: "Prijs",
        noDescription: "Geen beschrijving beschikbaar.",
        viewDetails: "Details Bekijken",
      },
      services: {
        title: "Onze Diensten",
        flexibleBooking: "Flexibel Boeken",
        flexibleBookingDesc: "Huur apparatuur voor een dag of een maand",
        deliveryOptions: "Bezorgopties",
        deliveryOptionsDesc: "Laat apparatuur op uw locatie bezorgen",
        support: "24/7 Ondersteuning",
        supportDesc: "Ons team staat altijd klaar om u te helpen",
        bestPrice: "Beste Prijs",
        bestPriceDesc: "We bieden concurrerende prijzen voor topapparatuur",
      },
      contact: {
        title: "Neem Contact Op",
        getInTouch: "Neem Contact Op",
        questions: "Heeft u vragen? We zijn er om u te helpen!",
        email: "E-mail:",
        phone: "Telefoon:",
        address: "Adres:",
        name: "Naam",
        message: "Bericht",
        send: "Bericht Versturen",
      },
      footer: {
        empowering: "Fotografen ondersteunen met eersteklas apparatuur",
        rights: "Alle rechten voorbehouden.",
        privacyPolicy: "Privacybeleid",
      },
      aboutUs_ourStory: "Ons Verhaal",
      aboutUs_ourStoryContent: "FotoRent, opgericht in 2024 door Aleksandra Safronova, ontstond uit een passie voor fotografie en een visie om hoogwaardige apparatuur toegankelijk te maken voor alle creatieven. Als eenmanszaak zijn we snel van idee naar realisatie gegaan en bedienen we nu actief de fotografiegemeenschap in Nederland.",
      aboutUs_ourMission: "Onze Missie",
      aboutUs_ourMissionContent: "Bij FotoRent is onze missie eenvoudig maar krachtig: professionele fotografie-apparatuur toegankelijk maken voor ieders creatieve ondernemingen. We geloven in het verleggen van de grenzen van creativiteit door eenvoudige toegang te bieden tot topapparatuur.",
      aboutUs_whatSetsUsApart: "Wat Ons Onderscheidt",
      aboutUs_whatSetsUsApartContent: "Als klein, wendbaar bedrijf zijn we trots op ons vermogen om gepersonaliseerde service en snelle communicatie te bieden. Onze omvang is onze kracht, waardoor we flexibel en responsief kunnen zijn op de behoeften van onze klanten.",
      aboutUs_meetTheFounder: "Ontmoet de Oprichter",
      aboutUs_meetTheFounderContent: "Aleksandra Safronova, het hart en de ziel van FotoRent, is momenteel met zwangerschapsverlof en zorgt voor haar dochter. Als grote fan van cinema en fotografie is Aleksandra's doel om creators te helpen hun visies tot leven te brengen door professionele apparatuur voor iedereen toegankelijk te maken.",
      aboutUs_lookingAhead: "Vooruitblik",
      aboutUs_lookingAheadContent: "Onze visie voor de toekomst is duidelijk: we streven ernaar een sterke klantenbasis op te bouwen, een solide reputatie te vestigen en onze catalogus van huurapparatuur voortdurend uit te breiden. We zijn toegewijd aan het groeien samen met de creatieve gemeenschap die we bedienen.",
      aboutUs_legalInformation: "Juridische Informatie",
      aboutUs_businessForm: "Bedrijfsvorm: Eenmanszaak",
      aboutUs_kvkNumber: "Kamer van Koophandel (KvK) Nummer: 94707367",
      aboutUs_vatNumber: "BTW-nummer: NL005103021B56",
      aboutUs_contactUs: "Voor vragen of om apparatuur te huren, aarzel niet om contact met ons op te nemen. We zijn er om uw creatieve projecten tot leven te brengen.",
      equipment_description: "Ontdek ons brede assortiment professionele fotografie- en videografie-apparatuur dat beschikbaar is voor verhuur. Of u nu een ervaren pro bent of een enthousiaste beginner, wij hebben de apparatuur om uw creatieve visie tot leven te brengen.",
      category_camera: "Camera",
      category_stabilizer: "Stabilisator",
      category_lighting: "Verlichting",
      category_lens: "Lens",
      specifications: "Specificaties",
      category_audio: "Audio",
      category_drone: "Drone",
      category_support: "Ondersteuning",
      howItWorks_description: "Het huren van fotografie- en videografie-apparatuur bij FotoRent is eenvoudig en gemakkelijk. Volg deze simpele stappen om de apparatuur te krijgen die u nodig heeft voor uw volgende creatieve project.",
      howItWorks_step1_title: "Kies Uw Apparatuur",
      howItWorks_step1_description: "Blader door onze uitgebreide catalogus en selecteer de apparatuur die u nodig heeft voor uw project.",
      howItWorks_step2_title: "Selecteer Huurdata",
      howItWorks_step2_description: "Kies de data waarop u de apparatuur nodig heeft. U kunt huren voor zo kort als één dag of zo lang als u nodig heeft.",
      howItWorks_step3_title: "Maak een Reservering",
      howItWorks_step3_description: "Voltooi uw boeking door uw gegevens te verstrekken en een veilige betaling te doen via onze partner, GearBooker.",
      howItWorks_step4_title: "Ophalen of Bezorgen",
      howItWorks_step4_description: "Haal uw apparatuur op bij onze locatie of kies voor onze handige bezorgservice.",
      howItWorks_step5_title: "Geniet van Uw Huur",
      howItWorks_step5_description: "Gebruik de apparatuur voor uw project. Ons ondersteuningsteam is altijd beschikbaar als u hulp nodig heeft.",
      howItWorks_step6_title: "Retourneer de Apparatuur",
      howItWorks_step6_description: "Breng de apparatuur terug naar onze locatie of plan een ophaalafspraak. Zorg ervoor dat het in dezelfde staat is als toen u het ontving.",
      howItWorks_importantInfo: "Belangrijke Informatie",
      howItWorks_importantInfo1: "Een geldig identiteitsbewijs en creditcard zijn vereist voor alle verhuringen.",
      howItWorks_importantInfo2: "We bieden verzekeringsopties om te beschermen tegen onopzettelijke schade of verlies.",
      howItWorks_importantInfo3: "Te late retourneringen kunnen extra kosten met zich meebrengen.",
      howItWorks_importantInfo4: "Annuleringen moeten minimaal 48 uur voor de begintijd van de huur worden gedaan voor een volledige terugbetaling.",
      howItWorks_importantInfo5: "Voor vragen of speciale verzoeken kunt u contact opnemen met ons klantenondersteuningsteam.",
      privacyPolicy: "Privacybeleid",
      privacyPolicy_introduction: "Inleiding",
      privacyPolicy_informationCollected: "Informatie die We Verzamelen",
      privacyPolicy_useOfInformation: "Hoe We Uw Informatie Gebruiken",
      privacyPolicy_dataSharing: "Gegevensdeling en Openbaarmaking",
      privacyPolicy_yourRights: "Uw Rechten",
      privacyPolicy_dataSecurity: "Gegevensbeveiliging",
      privacyPolicy_policyChanges: "Wijzigingen in Dit Beleid",
      privacyPolicy_contactUs: "Neem Contact Met Ons Op",
      privacyPolicy_introductionContent: "Bij FotoRent zetten we ons in voor het beschermen van uw privacy en het waarborgen van de veiligheid van uw persoonlijke informatie. Dit Privacybeleid legt uit hoe we uw informatie verzamelen, gebruiken, delen en beschermen wanneer u onze website en diensten gebruikt. We voldoen aan de Algemene Verordening Gegevensbescherming (AVG) en Nederlandse privacywetten om ervoor te zorgen dat uw privacyrechten worden gerespecteerd en gehandhaafd.",
      privacyPolicy_informationCollectedContent1: "We verzamelen de volgende informatie die u rechtstreeks aan ons verstrekt:",
      privacyPolicy_informationCollectedItem1: "Contactgegevens (naam, e-mailadres, telefoonnummer)",
      privacyPolicy_informationCollectedItem2: "Communicatie met ons (bijv. vragen, verzoeken voor klantenondersteuning)",
      privacyPolicy_note: "Opmerking:",
      privacyPolicy_informationCollectedContent2: "We verzamelen geen accountgegevens (zoals gebruikersnaam en wachtwoord), betalingsgegevens (zoals creditcardgegevens en factuuradres), of verhuurgeschiedenis en voorkeuren, aangezien onze site momenteel dergelijke functionaliteiten niet ondersteunt.",
      privacyPolicy_useOfInformationContent: "We gebruiken uw informatie om:",
      privacyPolicy_useOfInformationItem1: "Onze diensten te leveren, te onderhouden en te verbeteren",
      privacyPolicy_useOfInformationItem2: "Te reageren op uw opmerkingen, vragen en verzoeken",
      privacyPolicy_useOfInformationItem3: "U technische mededelingen, updates en ondersteuningsberichten te sturen",
      privacyPolicy_useOfInformationItem4: "Uw ervaring op onze website te personaliseren",
      privacyPolicy_dataSharingContent: "We verkopen uw persoonlijke informatie niet. We kunnen uw informatie delen in de volgende omstandigheden:",
      privacyPolicy_dataSharingItem1: "Dienstverleners die diensten voor ons uitvoeren",
      privacyPolicy_dataSharingItem2: "Juridische en regelgevende instanties, zoals vereist door de wet",
      privacyPolicy_dataSharingItem3: "Zakelijke partners, met uw toestemming",
      privacyPolicy_yourRightsContent: "Onder de AVG en Nederlandse privacywetten heeft u het recht om:",
      privacyPolicy_yourRightsItem1: "Toegang te krijgen tot uw persoonlijke gegevens",
      privacyPolicy_yourRightsItem2: "Onjuiste of onvolledige gegevens te corrigeren",
      privacyPolicy_yourRightsItem3: "Verwijdering van uw gegevens aan te vragen",
      privacyPolicy_yourRightsItem4: "Bezwaar te maken tegen of bepaalde verwerking van uw gegevens te beperken",
      privacyPolicy_yourRightsItem5: "Gegevensoverdraagbaarheid",
      privacyPolicy_dataSecurityContent: "We implementeren passende technische en organisatorische maatregelen om uw persoonlijke informatie te beschermen tegen ongeautoriseerde of onwettige verwerking, onopzettelijk verlies, vernietiging of beschadiging. Houd er echter rekening mee dat geen enkele methode van overdracht via internet of methode van elektronische opslag 100% veilig is. Hoewel we streven naar het gebruik van commercieel aanvaardbare middelen om uw persoonlijke informatie te beschermen, kunnen we de absolute veiligheid ervan niet garanderen.",
      privacyPolicy_policyChangesContent: "We kunnen dit beleid van tijd tot tijd bijwerken. We zullen u op de hoogte stellen van eventuele wijzigingen door het nieuwe beleid op deze pagina te plaatsen en de datum 'Laatst bijgewerkt' bij te werken. We moedigen u aan om dit beleid periodiek te bekijken om op de hoogte te blijven van hoe we uw informatie beschermen.",
      privacyPolicy_contactUsContent: "Als u vragen heeft over dit Privacybeleid, neem dan contact met ons op via:",
      privacyPolicy_lastUpdated: "Laatst bijgewerkt:",
    }
  };