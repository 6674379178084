import React, { useContext } from 'react';
import { LanguageContext } from './App';
import { translations } from './translations';

const AboutUs = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-primary dark:text-white">{t.aboutUs}</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-primary dark:text-white">{t.aboutUs_ourStory}</h2>
        <p className="mb-4 text-text dark:text-gray-300">
          {t.aboutUs_ourStoryContent}
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-primary dark:text-white">{t.aboutUs_ourMission}</h2>
        <p className="mb-4 text-text dark:text-gray-300">
          {t.aboutUs_ourMissionContent}
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-primary dark:text-white">{t.aboutUs_whatSetsUsApart}</h2>
        <p className="mb-4 text-text dark:text-gray-300">
          {t.aboutUs_whatSetsUsApartContent}
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-primary dark:text-white">{t.aboutUs_meetTheFounder}</h2>
        <p className="mb-4 text-text dark:text-gray-300">
          {t.aboutUs_meetTheFounderContent}
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-primary dark:text-white">{t.aboutUs_lookingAhead}</h2>
        <p className="mb-4 text-text dark:text-gray-300">
          {t.aboutUs_lookingAheadContent}
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-primary dark:text-white">{t.aboutUs_legalInformation}</h2>
        <ul className="list-disc pl-5 text-text dark:text-gray-300">
          <li>{t.aboutUs_businessForm}</li>
          <li>{t.aboutUs_kvkNumber}</li>
          <li>{t.aboutUs_vatNumber}</li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4 text-primary dark:text-white">{t.contact.title}</h2>
        <p className="mb-4 text-text dark:text-gray-300">
          {t.aboutUs_contactUs}
        </p>
        <p className="text-text dark:text-gray-300">{t.contact.email} info@fotorent.nl</p>
        <p className="text-text dark:text-gray-300">{t.contact.phone} +31610062565</p>
        <p className="text-text dark:text-gray-300">{t.contact.address} FotoRent, Punt Sniep 308, 1112 AD Diemen, Netherlands</p>
      </section>
    </div>
  );
};

export default AboutUs;