import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { motion, useScroll, useTransform } from 'framer-motion';
import FocusTrap from 'focus-trap-react';
import { ReactComponent as CameraIcon } from './icons/Camera.svg';
import { ReactComponent as CalendarIcon } from './icons/Calendar.svg';
import { ReactComponent as TruckIcon } from './icons/Truck.svg';
import { ReactComponent as ClockIcon } from './icons/Clock.svg';
import { ReactComponent as DollarSignIcon } from './icons/DollarSign.svg';
import { ReactComponent as MoonIcon } from './icons/Moon.svg';
import { ReactComponent as SunIcon } from './icons/Sun.svg';
import CookieConsent from './CookieConsent';
import PrivacyPolicy from './PrivacyPolicy';
import AboutUs from './AboutUs';
import Equipment from './Equipment';
import HowItWorks from './HowItWorks';
import EquipmentDetail from './EquipmentDetail';
import { translations } from './translations';

export const LanguageContext = createContext();
export const ThemeContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('language');
    const browserLanguage = navigator.language.split('-')[0];
    return savedLanguage || (browserLanguage === 'nl' ? 'nl' : 'en');
  });

  useEffect(() => {
    localStorage.setItem('language', language);
    document.documentElement.lang = language;
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => setIsDarkMode(mediaQuery.matches);
    
    setIsDarkMode(mediaQuery.matches);
    mediaQuery.addListener(handleChange);
    
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

const Header = () => {
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const handleRentNow = () => {
    window.location.href = 'https://www.gearbooker.com';
  };

  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleLogoClick();
    }
  };

  const toggleLanguage = () => {
    setLanguage(lang => lang === 'en' ? 'nl' : 'en');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const t = translations[language];

  return (
    <header className="fixed top-0 left-0 right-0 bg-primary dark:bg-gray-900 text-white p-2 shadow-md z-50">
      <div className="container mx-auto flex justify-between items-center">
        <div 
          className="flex items-center cursor-pointer" 
          onClick={handleLogoClick} 
          onKeyDown={handleKeyDown}
          role="button" 
          tabIndex={0} 
          aria-label="Go to home page"
        >
          <CameraIcon style={{color: '#FF6F3C'}} className="mr-2" width={36} height={36} aria-hidden="true" />
          <span className="text-xl font-bold">FotoRent</span>
        </div>
        <nav className="hidden md:block">
          <ul className="flex space-x-4">
            <li><Link to="/about" className="hover:text-secondary transition duration-300">{t.aboutUs}</Link></li>
            <li><Link to="/equipment" className="hover:text-secondary transition duration-300">{t.equipment}</Link></li>
            <li><Link to="/how-it-works" className="hover:text-secondary transition duration-300">{t.howItWorks}</Link></li>
          </ul>
        </nav>
        <div className="flex items-center">
          <button 
            onClick={toggleDarkMode} 
            className="mr-2 text-white p-2" 
            aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
          >
            {isDarkMode ? <SunIcon width={20} height={20} aria-hidden="true" /> : <MoonIcon width={20} height={20} aria-hidden="true" />}
          </button>
          <button 
            onClick={toggleLanguage}
            className="mr-2 text-white p-2"
            aria-label="Switch language"
          >
            {language === 'en' ? 'NL' : 'EN'}
          </button>
          <button 
            className="bg-secondary text-white px-3 py-1 rounded-full text-sm transition duration-300 shadow-lg hover:bg-[#ff5a1f]"
            onClick={handleRentNow}
            aria-label="Rent equipment now"
          >
            {t.rentNow}
          </button>
        </div>
        <button 
          className="md:hidden ml-2 p-2" 
          onClick={toggleMenu} 
          aria-expanded={isMenuOpen} 
          aria-label="Toggle menu"
        >
          {isMenuOpen ? "✕" : "☰"}
        </button>
      </div>
      {isMenuOpen && (
        <>
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-40" 
            onClick={closeMenu}
            aria-hidden="true"
          ></div>
          <FocusTrap>
            <div 
              ref={menuRef} 
              className="md:hidden fixed top-14 right-0 w-64 bg-primary dark:bg-gray-900 p-4 z-50" 
              role="menu"
            >
              <button 
                className="absolute top-2 right-2 text-white" 
                onClick={closeMenu}
                aria-label="Close menu"
              >
                ✕
              </button>
              <ul className="flex flex-col space-y-2">
                <li><Link to="/about" className="block py-1 px-2 hover:text-secondary transition duration-300" role="menuitem" onClick={closeMenu}>{t.aboutUs}</Link></li>
                <li><Link to="/equipment" className="block py-1 px-2 hover:text-secondary transition duration-300" role="menuitem" onClick={closeMenu}>{t.equipment}</Link></li>
                <li><Link to="/how-it-works" className="block py-1 px-2 hover:text-secondary transition duration-300" role="menuitem" onClick={closeMenu}>{t.howItWorks}</Link></li>
              </ul>
            </div>
          </FocusTrap>
        </>
      )}
    </header>
  );
};

const Hero = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 500], [0, -150]);

  const handleStartRenting = () => {
    window.location.href = 'https://www.gearbooker.com';
  };

  return (
    <motion.section 
      className="bg-cover bg-center py-20 md:py-32 relative overflow-hidden"
      style={{ 
        y,
        backgroundImage: "url('/images/hero-background.png')"
      }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="container mx-auto text-center relative z-10 px-4">
        <motion.h1 
          className="text-white text-4xl md:text-6xl font-bold mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          {t.hero.title}
        </motion.h1>
        <motion.p 
          className="text-white text-xl md:text-2xl mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          {t.hero.subtitle}
        </motion.p>
        <motion.button 
          className="bg-secondary text-white text-lg px-8 py-3 rounded-full transition duration-300 shadow-lg hover:bg-[#ff5a1f] focus:outline-none focus:ring-2 focus:ring-secondary"
          onClick={handleStartRenting}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          aria-label="Start renting equipment"
        >
          {t.hero.cta}
        </motion.button>
      </div>
    </motion.section>
  );
};

const FeaturedEquipment = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [selectedEquipment, setSelectedEquipment] = useState(null);

  const handleQuickReserve = (e) => {
    e.stopPropagation();
    window.location.href = 'https://www.gearbooker.com';
  };

  const equipmentList = [
    {
      id: 1,
      name: 'Canon R8 Mirrorless Camera Kit',
      category: 'Camera',
      price: '€80/day',
      image: '/images/canon-r8-kit.jpg',
      description: 'Full-frame mirrorless camera with 24-50mm RF lens, 128GB SD card, and 2 batteries.',
      specs: [
        '24.2MP Full-Frame CMOS Sensor',
        'DIGIC X Image Processor',
        '4K60p 10-Bit Internal Video',
        'Dual Pixel CMOS AF II',
        'RF 24-50mm f/4.5-6.3 IS STM Lens',
        '2.36m-Dot OLED EVF',
        '3" 1.62m-Dot Vari-Angle Touchscreen LCD',
        'Up to 40 fps E. Shutter, 6 fps Mech.',
        'Sensor-Shift 5-Axis Image Stabilization',
        'Dual SD UHS-II Memory Card Slots'
      ]
    },
    {
      id: 2,
      name: 'Canon RF 70-200mm f/4L IS USM Lens',
      category: 'Lens',
      price: '€40/day',
      image: '/images/canon-rf-70-200.jpg',
      description: 'Versatile telephoto zoom lens with image stabilization for Canon RF mount.',
      specs: [
        'RF-Mount Lens/Full-Frame Format',
        'Aperture Range: f/4 to f/32',
        'Four UD Elements',
        'Air Sphere Coating',
        'Dual Nano USM AF System',
        'Up to 5-Stop Image Stabilization',
        'Customizable Control Ring',
        'Weather-Sealed Construction',
        'Fluorine Coating',
        'Rounded 9-Blade Diaphragm'
      ]
    },
    {
      id: 3,
      name: 'Viltrox RF 85mm f/1.8 STM Lens',
      category: 'Lens',
      price: '€20/day',
      image: '/images/viltrox-rf-85.jpg',
      description: 'Fast aperture prime lens for portraits, compatible with Canon RF mount.',
      specs: [
        'RF-Mount Lens/Full-Frame Format',
        'Aperture Range: f/1.8 to f/16',
        'One ED Element, Four HR Elements',
        'Nano Multi-Layer Coating',
        'STM Stepping AF Motor',
        'Customizable Control Ring',
        'Rounded 9-Blade Diaphragm',
        'Weather-Sealed Construction',
        'Minimum Focusing Distance: 2.8',
        'Filter Thread: 72mm'
      ]
    },
    {
      id: 4,
      name: 'Canon RF 35mm f/1.8 Macro IS STM Lens',
      category: 'Lens',
      price: '€25/day',
      image: '/images/canon-rf-35.jpg',
      description: 'Compact wide-angle lens with macro capabilities and image stabilization.',
      specs: [
        'RF-Mount Lens/Full-Frame Format',
        'Aperture Range: f/1.8 to f/22',
        'Super Spectra Coating',
        'Maximum Magnification: 0.5x',
        'Minimum Focusing Distance: 6.7"',
        'STM Stepping AF Motor',
        'Optical Image Stabilizer',
        'Customizable Control Ring',
        'Rounded 9-Blade Diaphragm',
        'Filter Thread: 52mm'
      ]
    }
  ];

  return (
    <section id="equipment" className="bg-white dark:bg-gray-900 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-primary dark:text-white text-3xl font-bold mb-6 text-center">
          {t.featuredEquipment.title}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {equipmentList.map((item) => (
            <motion.div 
              key={item.id} 
              className="bg-background dark:bg-gray-800 rounded-lg shadow-md overflow-hidden cursor-pointer transition duration-300 hover:shadow-xl"
              onClick={() => setSelectedEquipment(item)}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ once: true, margin: "-50px" }}
            >
              <img src={item.image} alt={item.name} className="w-full h-48 object-cover" />
              <div className="p-4">
                <h3 className="text-primary dark:text-white text-lg font-semibold mb-1 truncate">{item.name}</h3>
                <p className="text-text dark:text-gray-300 text-sm mb-2">{t[`category_${item.category.toLowerCase()}`]}</p>
                <p className="text-secondary font-bold mb-3">{item.price}</p>
                <button 
                  className="bg-accent w-full text-white py-2 rounded-full text-sm transition duration-300 hover:bg-[#3d8ca8] focus:outline-none focus:ring-2 focus:ring-accent"
                  onClick={handleQuickReserve}
                  aria-label={`Quick reserve ${item.name}`}
                >
                  {t.featuredEquipment.quickReserve}
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
      {selectedEquipment && (
        <EquipmentDetail 
          equipment={selectedEquipment} 
          onClose={() => setSelectedEquipment(null)} 
        />
      )}
    </section>
  );
};

const Services = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const services = [
    { icon: <CalendarIcon width={36} height={36} />, title: t.services.flexibleBooking, description: t.services.flexibleBookingDesc },
    { icon: <TruckIcon width={36} height={36} />, title: t.services.deliveryOptions, description: t.services.deliveryOptionsDesc },
    { icon: <ClockIcon width={36} height={36} />, title: t.services.support, description: t.services.supportDesc },
    { icon: <DollarSignIcon width={36} height={36} />, title: t.services.bestPrice, description: t.services.bestPriceDesc }
  ];
  return (
    <section className="bg-background dark:bg-gray-800 py-8" aria-labelledby="services-title">
      <div className="container mx-auto px-4">
        <h2 id="services-title" className="text-primary dark:text-white text-2xl font-bold mb-6 text-center">{t.services.title}</h2>
        <div className="space-y-4">
          {services.map((service, index) => (
            <div key={index} className="flex items-center">
              <div className="text-accent mr-4" aria-hidden="true">
                {service.icon}
              </div>
              <div>
                <h3 className="text-primary dark:text-white text-lg font-semibold">{service.title}</h3>
                <p className="text-text dark:text-gray-300 text-sm">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Contact = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    alert('Thank you for your message. We will get back to you soon!');
    formRef.current.reset();
  };

  return (
    <section id="contact" className="bg-background dark:bg-gray-800 py-8" aria-labelledby="contact-title">
      <div className="container mx-auto px-4">
        <h2 id="contact-title" className="text-primary dark:text-white text-2xl font-bold mb-6 text-center">{t.contact.title}</h2>
        <div className="bg-white dark:bg-gray-900 rounded-lg shadow-xl overflow-hidden">
          <div className="p-6 text-primary dark:text-white">
            <h3 className="text-xl font-semibold mb-4">{t.contact.getInTouch}</h3>
            <p className="mb-4">{t.contact.questions}</p>
            <div className="mb-4">
              <p className="font-semibold">{t.contact.email}</p>
              <p>info@fotorent.nl</p>
            </div>
            <div className="mb-4">
              <p className="font-semibold">{t.contact.phone}</p>
              <p>+31610062565</p>
            </div>
            <div>
              <p className="font-semibold">{t.contact.address}</p>
              <p>FotoRent, Punt Sniep 308, 1112 AD Diemen, Netherlands</p>
            </div>
          </div>
          <div className="bg-gray-100 dark:bg-gray-800 p-6">
            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{t.contact.name}</label>
                <input 
                  type="text" 
                  id="name" 
                  name="name" 
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-primary dark:text-gray-300" 
                  required 
                  aria-required="true"
                />
              </div>
              
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{t.contact.email}</label>
                <input 
                  type="email" 
                  id="email" 
                  name="email" 
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-primary dark:text-gray-300" 
                  required 
                  aria-required="true"
                />
              </div>
              
              <div className="mb-4">
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{t.contact.message}</label>
                <textarea 
                  id="message" 
                  name="message" 
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-primary dark:text-gray-300" 
                  rows="4" 
                  required 
                  aria-required="true"
                ></textarea>
              </div>
              
              <button 
                className="bg-secondary text-white px-6 py-2 rounded-full text-sm transition duration-300 shadow-lg hover:bg-[#ff5a1f] focus:outline-none focus:ring-2 focus:ring-secondary" 
                type="submit"
              >
                {t.contact.send}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const Footer = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
    <footer className="bg-primary dark:bg-gray-900 text-white py-6">
      <div className="container mx-auto px-4 text-center">
        <div className="flex justify-center items-center mb-4">
          <CameraIcon style={{color: '#FF6F3C'}} className="mr-2" width={36} height={36} aria-hidden="true" />
          <span className="text-xl font-bold">FotoRent</span>
        </div>
        <p className="text-sm mb-2">{t.footer.empowering}</p>
        <p className="text-sm">&copy; 2024 FotoRent. {t.footer.rights}</p>
        <Link to="/privacy-policy" className="text-sm text-secondary hover:underline focus:outline-none focus:ring-2 focus:ring-secondary">{t.footer.privacyPolicy}</Link>
      </div>
    </footer>
  );
};

const HomePage = () => (
  <>
    <Hero />
    <FeaturedEquipment />
    <Services />
    <Contact />
  </>
);

const App = () => {
  const [isCookieConsentActive, setIsCookieConsentActive] = useState(false);

  const handleCookieConsentChange = (isActive) => {
    setIsCookieConsentActive(isActive);
  };

  return (
    <HelmetProvider>
      <Router>
        <LanguageProvider>
          <ThemeProvider>
            <div className="font-sans pt-14 bg-white dark:bg-gray-900">
              <a href="#main-content" className="sr-only focus:not-sr-only focus:absolute focus:top-0 focus:left-0 focus:z-50 focus:bg-secondary focus:text-white focus:p-4">Skip to main content</a>
              <Header />
              <main id="main-content">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/about" element={<AboutUs />} />
                  <Route path="/equipment" element={<Equipment />} />
                  <Route path="/how-it-works" element={<HowItWorks />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
              </main>
              <Footer />
              {isCookieConsentActive && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-40" aria-hidden="true" />
              )}
              <CookieConsent onConsentChange={handleCookieConsentChange} />
            </div>
          </ThemeProvider>
        </LanguageProvider>
      </Router>
    </HelmetProvider>
  );
};

export default App;