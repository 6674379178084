import React, { useContext, useEffect } from 'react';
import { LanguageContext } from './App';
import { translations } from './translations';

const EquipmentDetail = ({ equipment, onClose }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-3xl w-full relative overflow-y-auto max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          aria-label="Close"
        >
          ✕
        </button>
        <h2 className="text-2xl font-bold mb-4 text-primary dark:text-white">{equipment.name}</h2>
        <img src={equipment.image} alt={equipment.name} className="w-full h-64 object-cover mb-4 rounded" />
        <p className="text-text dark:text-gray-300 mb-2"><strong>{t.category}:</strong> {t[`category_${equipment.category.toLowerCase()}`]}</p>
        <p className="text-text dark:text-gray-300 mb-2"><strong>{t.price}:</strong> {equipment.price}</p>
        <p className="text-text dark:text-gray-300 mb-4">{equipment.description}</p>
        <h3 className="text-xl font-semibold mb-2 text-primary dark:text-white">{t.specifications}</h3>
        <ul className="list-disc pl-5 mb-4 text-text dark:text-gray-300">
          {equipment.specs.map((spec, index) => (
            <li key={index}>{spec}</li>
          ))}
        </ul>
        <button 
          className="bg-secondary text-white px-4 py-2 rounded-full text-sm transition duration-300 hover:bg-[#ff5a1f] focus:outline-none focus:ring-2 focus:ring-secondary"
          onClick={() => window.location.href = 'https://www.gearbooker.com'}
        >
          {t.rentNow}
        </button>
      </div>
    </div>
  );
};

export default EquipmentDetail; 