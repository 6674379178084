import React, { useContext } from 'react';
import { ReactComponent as CameraIcon } from './icons/Camera.svg';
import { ReactComponent as CalendarIcon } from './icons/Calendar.svg';
import { ReactComponent as TruckIcon } from './icons/Truck.svg';
import { ReactComponent as CreditCardIcon } from './icons/CreditCard.svg';
import { LanguageContext } from './App';
import { translations } from './translations';

const HowItWorks = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const steps = [
    { icon: <CameraIcon width={36} height={36} />, title: t.howItWorks_step1_title, description: t.howItWorks_step1_description },
    { icon: <CalendarIcon width={36} height={36} />, title: t.howItWorks_step2_title, description: t.howItWorks_step2_description },
    { icon: <CreditCardIcon width={36} height={36} />, title: t.howItWorks_step3_title, description: t.howItWorks_step3_description },
    { icon: <TruckIcon width={36} height={36} />, title: t.howItWorks_step4_title, description: t.howItWorks_step4_description },
    { icon: <CameraIcon width={36} height={36} />, title: t.howItWorks_step5_title, description: t.howItWorks_step5_description },
    { icon: <TruckIcon width={36} height={36} />, title: t.howItWorks_step6_title, description: t.howItWorks_step6_description }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-primary dark:text-white">{t.howItWorks}</h1>
      <p className="mb-8 text-text dark:text-gray-300">
        {t.howItWorks_description}
      </p>
      
      <div className="space-y-8">
        {steps.map((step, index) => (
          <div key={index} className="flex items-start">
            <div className="bg-accent text-white p-3 rounded-full mr-4">
              {step.icon}
            </div>
            <div>
              <h2 className="text-xl font-semibold mb-2 text-primary dark:text-white">{step.title}</h2>
              <p className="text-text dark:text-gray-300">{step.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-12 bg-gray-100 dark:bg-gray-800 p-6 rounded-lg">
        <h2 className="text-2xl font-bold mb-4 text-primary dark:text-white">{t.howItWorks_importantInfo}</h2>
        <ul className="list-disc pl-5 space-y-2 text-text dark:text-gray-300">
          <li>{t.howItWorks_importantInfo1}</li>
          <li>{t.howItWorks_importantInfo2}</li>
          <li>{t.howItWorks_importantInfo3}</li>
          <li>{t.howItWorks_importantInfo4}</li>
          <li>{t.howItWorks_importantInfo5}</li>
        </ul>
      </div>
    </div>
  );
};

export default HowItWorks;