import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Search, Grid, List } from 'lucide-react';
import { LanguageContext } from './App';
import { translations } from './translations';
import EquipmentDetail from './EquipmentDetail';

const equipmentList = [
  {
    id: 1,
    name: 'Canon R8 Mirrorless Camera Kit',
    category: 'Camera',
    price: '€80/day',
    image: '/images/canon-r8-kit.jpg',
    description: 'Full-frame mirrorless camera with 24-50mm RF lens, 128GB SD card, and 2 batteries. Perfect for both photography and 4K video recording.',
    specs: [
      '24.2MP Full-Frame CMOS Sensor',
      'DIGIC X Image Processor',
      '4K60p 10-Bit Internal Video',
      'Dual Pixel CMOS AF II',
      'RF 24-50mm f/4.5-6.3 IS STM Lens',
      '2.36m-Dot OLED EVF',
      '3" 1.62m-Dot Vari-Angle Touchscreen LCD',
      'Up to 40 fps E. Shutter, 6 fps Mech.',
      'Sensor-Shift 5-Axis Image Stabilization',
      'Dual SD UHS-II Memory Card Slots'
    ]
  },
  {
    id: 2,
    name: 'Canon RF 70-200mm f/4L IS USM Lens',
    category: 'Lens',
    price: '€40/day',
    image: '/images/canon-rf-70-200.jpg',
    description: 'Versatile telephoto zoom lens with image stabilization for Canon RF mount. Perfect for various shooting scenarios, from portraits to sports and wildlife photography.',
    specs: [
      'RF-Mount Lens/Full-Frame Format',
      'Aperture Range: f/4 to f/32',
      'Four UD Elements',
      'Air Sphere Coating',
      'Dual Nano USM AF System',
      'Up to 5-Stop Image Stabilization',
      'Customizable Control Ring',
      'Weather-Sealed Construction',
      'Fluorine Coating',
      'Rounded 9-Blade Diaphragm'
    ]
  },
  {
    id: 3,
    name: 'Viltrox RF 85mm f/1.8 STM Lens',
    category: 'Lens',
    price: '€20/day',
    image: '/images/viltrox-rf-85.jpg',
    description: 'High-quality, affordable prime lens for Canon RF mount cameras. Perfect for portrait photography, offering a classic 85mm focal length and a wide f/1.8 aperture for beautiful background blur.',
    specs: [
      'RF-Mount Lens/Full-Frame Format',
      'Aperture Range: f/1.8 to f/16',
      'One ED Element, Four HR Elements',
      'Nano Multi-Layer Coating',
      'STM Stepping AF Motor',
      'Customizable Control Ring',
      'Rounded 9-Blade Diaphragm',
      'Weather-Sealed Construction',
      'Minimum Focusing Distance: 2.8',
      'Filter Thread: 72mm'
    ]
  },
  {
    id: 4,
    name: 'Canon RF 35mm f/1.8 Macro IS STM Lens',
    category: 'Lens',
    price: '€25/day',
    image: '/images/canon-rf-35.jpg',
    description: 'Versatile wide-angle prime lens for Canon RF mount cameras. Combines a popular 35mm focal length with macro capabilities, allowing you to capture both environmental shots and close-up details.',
    specs: [
      'RF-Mount Lens/Full-Frame Format',
      'Aperture Range: f/1.8 to f/22',
      'Super Spectra Coating',
      'Maximum Magnification: 0.5x',
      'Minimum Focusing Distance: 6.7"',
      'STM Stepping AF Motor',
      'Optical Image Stabilizer',
      'Customizable Control Ring',
      'Rounded 9-Blade Diaphragm',
      'Filter Thread: 52mm'
    ]
  }
];

const EquipmentSchema = ({ equipmentList }) => (
  <Helmet>
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "ItemList",
        "itemListElement": equipmentList.map((item, index) => ({
          "@type": "ListItem",
          "position": index + 1,
          "item": {
            "@type": "Product",
            "name": item.name,
            "image": item.image,
            "description": item.description,
            "offers": {
              "@type": "Offer",
              "url": `https://fotorent.nl/equipment/${item.id}`,
              "priceCurrency": "EUR",
              "price": item.price.replace('€', '').replace('/day', ''),
              "priceValidUntil": "2024-12-31",
              "availability": "https://schema.org/InStock"
            }
          }
        }))
      })}
    </script>
  </Helmet>
);

const Equipment = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [view, setView] = useState('grid');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEquipment, setSelectedEquipment] = useState(null);

  const handleQuickReserve = (e) => {
    e.stopPropagation(); // Предотвращаем открытие модального окна при нажатии на кнопку
    window.location.href = 'https://www.gearbooker.com';
  };

  const filteredEquipment = equipmentList.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <EquipmentSchema equipmentList={equipmentList} />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6 text-primary dark:text-white">{t.equipment}</h1>
        <div className="mb-6 flex justify-between items-center">
          <div className="relative">
            <input
              type="text"
              placeholder={t.featuredEquipment.searchEquipment}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-secondary"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => setView('grid')}
              className={`p-2 rounded-full ${view === 'grid' ? 'bg-secondary text-white' : 'bg-gray-200 text-gray-600'}`}
              aria-label="Grid view"
            >
              <Grid size={20} />
            </button>
            <button
              onClick={() => setView('list')}
              className={`p-2 rounded-full ${view === 'list' ? 'bg-secondary text-white' : 'bg-gray-200 text-gray-600'}`}
              aria-label="List view"
            >
              <List size={20} />
            </button>
          </div>
        </div>
        
        <div className={view === 'grid' ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6" : "space-y-4"}>
          {filteredEquipment.map((item) => (
            <div 
              key={item.id} 
              className={`bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden ${view === 'list' ? 'flex' : ''} cursor-pointer transition duration-300 hover:shadow-xl`}
              onClick={() => setSelectedEquipment(item)}
            >
              <img src={item.image} alt={item.name} className={view === 'grid' ? "w-full h-48 object-cover" : "w-1/3 object-cover"} />
              <div className={view === 'grid' ? "p-4" : "p-4 flex-grow"}>
                <h3 className="text-primary dark:text-white text-lg font-semibold mb-1">{item.name}</h3>
                <p className="text-text dark:text-gray-300 text-sm mb-2">{t[`category_${item.category.toLowerCase()}`]}</p>
                <p className="text-secondary font-bold mb-3">{item.price}</p>
                <button 
                  className="bg-accent w-full text-white py-2 rounded-full text-sm transition duration-300 hover:bg-[#3d8ca8] focus:outline-none focus:ring-2 focus:ring-accent"
                  onClick={handleQuickReserve}
                  aria-label={`Quick reserve ${item.name}`}
                >
                  {t.featuredEquipment.quickReserve}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedEquipment && (
        <EquipmentDetail 
          equipment={selectedEquipment} 
          onClose={() => setSelectedEquipment(null)} 
        />
      )}
    </>
  );
};

export default Equipment;